import React, { Component } from 'react';
import styled, { css } from 'styled-components';
import { LoginHeader } from './LoginHeader';
import { provideLocalizationService, registerForLocalization } from "@progress/kendo-react-intl";
import { respondTo, elevation } from '../ui/components/shared';

const Container = styled.div`
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  ${respondTo('xs', css`
    padding-top: 64px;
  `)} 
`;

const LoginContainer = styled.div`
  padding: 2em 1em;
  width: 100%;
  height: 100%;

  ${respondTo('xs', css`
    max-width: 500px;
    height:auto;
    padding: 3em 2em;
    ${elevation(4)}
  `)}
`;

export class Layout extends Component {
  render() {
    const localizationService = provideLocalizationService(this);
    document.title = localizationService.toLanguageString("custom.title");

    return (
      <Container>   
          <LoginContainer>
            <LoginHeader onLanguageChange={this.props.onLanguageChange} />
            {this.props.children}          
          </LoginContainer>
      </Container>
    );
  }
}

registerForLocalization(Layout);
