import React, { Component } from 'react';
import { provideLocalizationService, registerForLocalization } from "@progress/kendo-react-intl";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import logoLt from './../lsmu-logo-lt-LT.png';
import logoEn from './../lsmu-logo-en-US.png';
import styled, { css } from 'styled-components';
import { respondTo, elevation } from '../ui/components/shared';

const LoginLogo = styled.div`
  padding-bottom: 16px;
`;

const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const LoginTitle = styled.div`
  padding-bottom: 32px;
  text-align: center;
  font-size: 16px;
 
  ${respondTo('xs', css`  
    font-size: 24px;
    padding-bottom: 16px;
  `)}
`;

const LanguageBar = styled.div`
  padding-bottom: 16px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
`;

const Group = styled(ButtonGroup).attrs(props => ({  
}))`
  ${elevation(1)}

  ${respondTo('xs', css`
    ${elevation(4)}
`)}
`;

const LanguageButton = styled(Button).attrs(props => ({  
}))`
  padding: 4px 8px;
  font-size: 12px;

  ${respondTo('xs', css`
    padding: 8px 16px;
    font-size: 14px;
  `)}
`;

export class LoginHeader extends Component {
  render () {
    const localizationService = provideLocalizationService(this);
    const logo = localizationService.language === 'en-US' ? logoEn : logoLt;
    return (
      <>
        <LoginLogo>
          <Img src={logo} alt="logo" />
        </LoginLogo>
        <LoginTitle>{localizationService.toLanguageString('custom.title')}</LoginTitle>
        <LanguageBar>
          <Group>
            <LanguageButton togglable={true} selected={localizationService.language === 'lt-LT'} onClick={() => this.props.onLanguageChange('lt-LT')}>LT</LanguageButton>
            <LanguageButton togglable={true} selected={localizationService.language === 'en-US'} onClick={() => this.props.onLanguageChange('en-US')}>EN</LanguageButton>
          </Group>
        </LanguageBar>
      </>
    );
  }
}

registerForLocalization(LoginHeader);
