import React, { Component } from 'react';

import { Form, Field, FormElement } from '@progress/kendo-react-form';
import Input from '../../components/shared/Input';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import axios from 'axios';
import { Error } from '@progress/kendo-react-labels';

export class Login extends Component {

  state = {
    requestParams: {},
    error: false,
    errorMessage: null,
    isLoading: false
  };

  componentDidMount() {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);

    const requestParams = {
      client_id: params.get('client_id'),
      redirect_uri: params.get('redirect_uri'),
      response_type: params.get('response_type'),
      state: params.get('state'),
      scope: params.get('scope'),
      code_challenge: params.get('code_challenge'),
      code_challenge_method: params.get('code_challenge_method')
    }

    this.setState({
      requestParams: requestParams
    });
  }

  render() {
    const localizationService = provideLocalizationService(this);
    const userValidator = (value) => value?.length > 0 ? '' : localizationService.toLanguageString('login.usernameRequired');
    const passValidator = (value) => value?.length > 0 ? '' : localizationService.toLanguageString('login.passwordRequired');

    const error = this.state.error
      ? (<Error>{this.state.errorMessage}</Error>)
      : null;

    const loginButtonText = this.state.isLoading
      ? localizationService.toLanguageString('login.loginProgress')
      : localizationService.toLanguageString('login.login');

    return (
      <Form
        onSubmit={this.handleSubmit}
        render={(formRenderProps) => (
          <FormElement>
            {error}
            <fieldset className={"k-form-fieldset"}>
              <div className="mb-3">
                <Field
                  name={"user"}
                  component={Input}
                  disabled={this.state.isLoading}
                  label={localizationService.toLanguageString('login.username')}
                  validator={userValidator}
                />
              </div>
              <div className="mb-3">
                <Field
                  name={"password"}
                  type={"password"}
                  component={Input}
                  disabled={this.state.isLoading}
                  label={localizationService.toLanguageString('login.password')}
                  validator={passValidator}/>
              </div>
            </fieldset>
            <div className="k-form-buttons">
              <button
                type={"submit"}
                className="k-button btn-primary"
                disabled={!formRenderProps.allowSubmit && !this.state.isLoading}>
                {loginButtonText}
              </button>
            </div>
          </FormElement>
        )}/>
    );
  }

  handleSubmit = (data) => {
    const localizationService = provideLocalizationService(this);
    this.setState({
      isLoading: true,
      error: false
    });
    const payload = {
      ...this.state.requestParams,
      username: data.user,
      password: data.password
    };

    axios
      .post('/login', payload)
      .then(response => {
        this.setState({
          isLoading: false
        });
        if (response.data && this.state.requestParams.redirect_uri) {
          window.location.href = `${this.state.requestParams.redirect_uri}?code=${response.data.authorization_code}`;
        }
      })
      .catch((error) => {
        let message;
        switch (error.response.status) {
          case 400:
            message = localizationService.toLanguageString('login.badCredentials');
            break;
          default:
            message = localizationService.toLanguageString('login.internalError');
            break;
        }
        console.log(error.response.status);
        this.setState({
          isLoading: false,
          error: true,
          errorMessage: message
        });
      });
  }
}

registerForLocalization(Login);