import React from "react";
import { Input as KendoInput } from '@progress/kendo-react-inputs';
import { Error } from "@progress/kendo-react-labels";

const Input = (props) => {
  const { validationMessage, visited, ...others } = props;

  return (
    <div>
      <KendoInput {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  )
};

export default Input;