import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import { Login } from './pages/Login/Login';
import '@progress/kendo-theme-material/dist/all.css';

import './custom.css'

import './messages/en-US';
import './messages/lt-LT';
import { IntlProvider, load, loadMessages, LocalizationProvider } from "@progress/kendo-react-intl";
import { enMessages } from "./messages/en-US";
import { ltMessages } from "./messages/lt-LT";


import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import usNumbers from 'cldr-numbers-full/main/en/numbers.json';
import usLocalCurrency from 'cldr-numbers-full/main/en/currencies.json';
import usCaGregorian from 'cldr-dates-full/main/en/ca-gregorian.json';
import usDateFields from'cldr-dates-full/main/en/dateFields.json';

import ltNumbers from 'cldr-numbers-full/main/lt/numbers.json';
import ltLocalCurrency from 'cldr-numbers-full/main/lt/currencies.json';
import ltCaGregorian from 'cldr-dates-full/main/lt/ca-gregorian.json';
import ltDateFields from'cldr-dates-full/main/lt/dateFields.json';

load(
  likelySubtags,
  currencyData,
  weekData,
  usNumbers,
  usLocalCurrency,
  usCaGregorian,
  usDateFields,
  ltNumbers,
  ltLocalCurrency,
  ltCaGregorian,
  ltDateFields
);

loadMessages(enMessages, 'en-US');
loadMessages(ltMessages, 'lt-LT');

export default class App extends Component {

  state = {
    localeId: 'lt-LT'
  };

  render () {
    return (
      <LocalizationProvider language={this.state.localeId}>
        <IntlProvider locale={this.state.localeId}>
          <Layout onLanguageChange={this.handleLanguageChange}>
            <Switch>
              <Route path='/' component={Login}/>
            </Switch>
          </Layout>
        </IntlProvider>
      </LocalizationProvider>
    );
  }

  handleLanguageChange = (localeId) => {
    this.setState({
      localeId: localeId
    });

    console.log("language changed");
  }
}
