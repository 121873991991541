export const ltMessages = {
  "custom": {
    "title": "Bendro prisijungimo sistema"
  },
  "login": {
    "username": "Vartotojo vardas",
    "password": "Slaptažodis",
    "usernameRequired": "Nurodykite vartotojo vardą",
    "passwordRequired": "Nurodykite slaptažodį",
    "login": "Prisijungti",
    "loginProgress": "Jungiamasi...",
    "badCredentials": "Blogas prisijungimo vardas arba slaptažodis",
    "internalError": "Sistemos klaida"
  },
  "scheduler": {
    "editorValidationRequired": "Laukas yra privalomas",
    "editorValidationStart": "Pradžios data negali buti didesnė už pabaigos datą",
    "editorValidationEnd": "Pabaigos data negali buti mažesnė už pabaigos datą",
    "allEvents": "Visi įvykiai",
    "allDay": "visą dieną",
    "today": "šiandiena",
    "dayViewTitle": "Diena",
    "weekViewTitle": "Savaitė",
    "monthViewTitle": "Mėnesis",
    "workWeekViewTitle": "Darbo savaitė",
    "timelineViewTitle": "Laiko juosta",
    "agendaViewTitle": "Legenda",
    "deleteTitle": "Trinti pavadinimą",
    "previousTitle": "Senas pavadinimas",
    "nextTitle": "Sekantis",
    "showFullDay": "Rodyti visą dieną",
    "showWorkDay": "Rodyti darbo valandas",
    "editorOccurrence": "Redaguoti dabartinį įvykį",
    "editorSeries": "Redaguoti grupę įvykių",
    "editorRecurringConfirmation": "Ar norite redaguoti vieną įvykį ar jų grupę?",
    "editorRecurringDialogTitle": "Redaguoti pasikartojantį įvykį",
    "editorSave": "Saugoti",
    "editorCancel": "Atšaukti",
    "editorDelete": "Pašalinti",
    "editorTitle": "Įvykis",
    "editorEventTitle": "Pavadinimas",
    "editorEventStart": "Pradžia",
    "editorEventStartTimeZone": "Pradžios laiko zona",
    "editorEventEnd": "Pabaiga",
    "editorEventEndTimeZone": "Pabaigos laiko zona",
    "editorEventAllDay": "Visos dienos įvykis",
    "editorEventDescription": "Aprašymas",
    "editorEventSeparateTimeZones": "Pabaiga skirtingoje laiko zonoje",
    "editorEventTimeZone": "Nurodyti laiko zoną",
    "recurrenceEditorRepeat": "Pasikartojantis",
    "recurrenceEditorDailyInterval": "Diena(-os)",
    "recurrenceEditorDailyRepeatEvery": "Kartoti kas dieną",
    "recurrenceEditorWeeklyInterval": "Savaitė(-ės)",
    "recurrenceEditorWeeklyRepeatEvery": "Kartoti kas savaitę",
    "recurrenceEditorWeeklyRepeatOn": "Kartoti kai",
    "recurrenceEditorMonthlyDay": "Diena",
    "recurrenceEditorMonthlyInterval": "Mėnesis(-iai)",
    "recurrenceEditorMonthlyRepeatEvery": "Kartoti kas mėnesį",
    "recurrenceEditorMonthlyRepeatOn": "Kartoti kai",
    "recurrenceEditorYearlyOf": "iš",
    "recurrenceEditorYearlyRepeatEvery": "Kartoti kas metus",
    "recurrenceEditorYearlyRepeatOn": "Kartoti kai",
    "recurrenceEditorYearlyInterval": "Metai",
    "recurrenceEditorFrequenciesDaily": "Kasdieninis",
    "recurrenceEditorFrequenciesMonthly": "Mėnesinis",
    "recurrenceEditorFrequenciesNever": "Niekada",
    "recurrenceEditorFrequenciesWeekly": "Savaitinis",
    "recurrenceEditorFrequenciesYearly": "Metinis",
    "recurrenceEditorOffsetPositionsFirst": "Pirmas",
    "recurrenceEditorOffsetPositionsSecond": "Antras",
    "recurrenceEditorOffsetPositionsThird": "Trečias",
    "recurrenceEditorOffsetPositionsFourth": "Ketvirtas",
    "recurrenceEditorOffsetPositionsLast": "Paskutinis",
    "recurrenceEditorWeekdaysDay": "Diena",
    "recurrenceEditorWeekdaysWeekday": "Savaitės diena",
    "recurrenceEditorWeekdaysWeekendday": "Savaitgalio diena",
    "recurrenceEditorEndAfter": "Po",
    "recurrenceEditorEndOccurrence": "Įvykiai",
    "recurrenceEditorEndLabel": "Pabaiga",
    "recurrenceEditorEndNever": "Niekada",
    "recurrenceEditorEndOn": "Kai",
    "deleteConfirmation": "Ar tikrai norite ištrinti šį įvykį?",
    "deleteRecurringConfirmation": "Ar norite pašalinti tik šį įvykį ar jų grupę?",
    "deleteOccurrence": "Pašaltini pasirinktą įvykį",
    "deleteSeries": "Pašalinti įvykių grupę",
    "deleteDialogTitle": "Pašalinti įvykį",
    "deleteRecurringDialogTitle": "Pašalinti pasikartojantį įvykį",
    "dateTitle": "Data",
    "timeTitle": "Laikas",
    "eventTitle": "Įvykis",
    "noEvents": "įvykių nėra"
  },
  "filter": {
    "eqOperator": "Lygus",
    "notEqOperator": "Nelygus",
    "isNullOperator": "Neužpildytas",
    "isNotNullOperator": "Užpildytas",
    "isEmptyOperator": "Tuščias",
    "isNotEmptyOperator": "Netuščias",
    "startsWithOperator": "Prasideda su",
    "containsOperator": "Turi",
    "notContainsOperator": "Neturi",
    "endsWithOperator": "Baigiasi su",
    "gteOperator": "Didesnis nei arba lygus",
    "gtOperator": "Didesnis nei",
    "lteOperator": "Mažesnis nei arba lygus",
    "ltOperator": "Mažesnis nei",
    "isTrue": "Yra",
    "isFalse": "Nėra",
    "afterOrEqualOperator": "Yra po arba lygus",
    "afterOperator": "Yra po",
    "beforeOperator": "Yra prieš",
    "beforeOrEqualOperator": "Yra prieš arba lygus",
    "andLogic": "Ir",
    "orLogic": "Arba",
    "addExpression": "Pridėti išraišką",
    "addGroup": "Pridėti grupę",
    "close": "Uždaryti"
  },
  "editor": {
    "bold": "Paryškintas",
    "italic": "Kursyvas",
    "underline": "Pabrauktas",
    "strikethrough": "Perbrauktas",
    "subscript": "Apatinis indeksas",
    "superscript": "Viršutinis indeksas",
    "unlink": "Pašalinti nuorodą",
    "undo": "Anuliuoti",
    "redo": "Perdaryti",
    "fontSize": "Šrifto dydis",
    "fontName": "Šrifto pavadinimas",
    "format": "Formatas",
    "alignLeft": "Lygiuoti kairėje",
    "alignRight": "Lygiuoti dešinėje",
    "alignCenter": "Lygiuoti centre",
    "alignJustify": "Abipuse lygiuotė",
    "indent": "Įtrauka",
    "outdent": "Ištrauka",
    "orderedList": "Įterpti rykiuotą sąršą",
    "bulletList": "Įterpti sąršą",
    "createTable": "Sukurti lentelę",
    "insertTableHint": "Sukurti {0} x {1} lentelę",
    "addRowBefore": "Įterpti eilutę virš",
    "addRowAfter": "Įterpti eilutę po",
    "addColumnBefore": "Įterpti stulepį kairėje",
    "addColumnAfter": "Įterpti stulpelį dešinėje",
    "deleteRow": "Pašalinti eilutę",
    "deleteColumn": "Pašalinti stulpelį",
    "deleteTable": "Pašalinti lentelę",
    "mergeCells": "Sujungti langelius",
    "splitCell": "Atskirti langelius",
    "hyperlink": "Įterpti nuorodą",
    "hyperlink-dialog-title": "Įterpti nuorodą",
    "hyperlink-dialog-content-address": "Adresas",
    "hyperlink-dialog-content-title": "Pavadinimas",
    "hyperlink-dialog-content-newwindow": "Atidaryti nuorodo kitame lange",
    "hyperlink-dialog-cancel": "Atšaukti",
    "hyperlink-dialog-insert": "Įterpti",
    "image": "Įterpti paveikslėlį",
    "image-dialog-title": "Įterpti paveikslėlį",
    "image-address": "Adresas",
    "image-title": "Pavadinimas",
    "image-altText": "Papildomas tekstas",
    "image-width": "Plotis (px)",
    "image-height": "Aukštis (px)",
    "image-cancel": "Atšaukti",
    "image-insert": "Įterpti",
    "viewHtml": "Peržiūrėti HTML",
    "viewHtml-dialog-title": "Peržiūrėti HTML",
    "viewHtml-cancel": "Atšaukti",
    "viewHtml-update": "Atnaujinti"
  },
  "dropdowns": {
    "nodata": "DUOMENŲ NERASTA.",
    "clear": "Valyti"
  },
  "upload": {
    "cancel": "Atšaukti",
    "clearSelectedFiles": "Išvalyti",
    "dropFilesHere": "Įtempkite norimus įkelti failus.",
    "headerStatusUploaded": "Baigta",
    "headerStatusUploading": "Įkeliama...",
    "invalidFileExtension": "Netinkamas failo formatas.",
    "invalidFiles": "Netinkamas failas(-ai). Patikrinkite failų reikalavimus.",
    "invalidMaxFileSize": "Failas per didelis.",
    "invalidMinFileSize": "Failas per mažas.",
    "remove": "Pašalinti",
    "retry": "Bandyti iš naujo",
    "select": "Pasirinkti failus...",
    "uploadSelectedFiles": "Įkelti",
    "total": "Viso",
    "files": "Failai"
  },
  "pager": {
    "itemsPerPage": "Įrašų per puslapį",
    "info": "{0} - {1} iš {2} įrašų",
    "firstPage": "Eiti į pirmą puslapį",
    "previousPage": "Atgal",
    "nextPage": "Pirmyn",
    "lastPage": "Eiti į paskutinį puslapį",
    "page": "Puslapis",
    "of": "iš"
  },
  "treelist": {
    "filterClearButton": "Valyti",
    "filterEqOperator": "Lygus",
    "filterNotEqOperator": "Nelygus",
    "filterIsNullOperator": "Neužpildytas",
    "filterIsNotNullOperator": "Užpildytas",
    "filterIsEmptyOperator": "Tuščias",
    "filterIsNotEmptyOperator": "Netuščias",
    "filterStartsWithOperator": "Prasideda su",
    "filterContainsOperator": "Turi",
    "filterNotContainsOperator": "Neturi",
    "filterEndsWithOperator": "Baigiasi su",
    "filterGteOperator": "Didesnis nei arba lygus",
    "filterGtOperator": "Didesnis nei",
    "filterLteOperator": "Mažesnis nei arba lygus",
    "filterLtOperator": "Mažesnis nei",
    "filterIsTrue": "Yra",
    "filterIsFalse": "Nėra",
    "filterBooleanAll": "(Visi)",
    "filterAfterOrEqualOperator": "Yra po ar lygus",
    "filterAfterOperator": "Yra po",
    "filterBeforeOperator": "Yra prieš",
    "filterBeforeOrEqualOperator": "Yra prieš arba lygus",
    "noRecords": "Nėra įrašų"
  },
  "daterangepicker": {
    "swapStartEnd": "Sukeisti pradžios ir pabaigos datas",
    "start": "Pradžia",
    "end": "Pabaiga",
    "separator": " "
  },
  "sortable": {
    "noData": "Nėra duomenų"
  }
}